import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { arrayToString, get_error_message_by_code } from '../helper'
import { maxFrontendUploadSize } from '../constants'
import SinglePhoto from './SinglePhoto'
import { toast } from 'react-toastify'
import { createResource, deleteModel, loadModels, saveModel, saveResource, uploadFileChunks } from '../common'
import { ReactComponent as Spinner } from '../loading_spinner.svg'
import useWindowHeight from '../hooks/WindowHeight'

const Photogallery = (props) => {

  const {field, height, imageModel, imageContainerModel, parentModel, baseUrl, mediaUrl, parentModelUuid, makeDisable, readOnly, changeHandler, initMultiValue, setHeightFunction, skipAlternateLoadingMethod} = props

  const [multiValue] = useState((initMultiValue && initMultiValue.length)?initMultiValue:[])
  const [makeReadOnly,setMakeReadOnly] = useState(readOnly)
  const [photos,setPhotos] = useState([])
  const [containerWidth,setContainerWidth] = useState(100)
  const [triggerReloadPhotos,setTriggerReloadPhotos] = useState(0)
  const [mouseOver,setMouseOver] = useState(false)
  const [innerOffset,setInnerOffset] = useState(0)
  const [numberOfPhotos,setNumberOfPhotos] = useState(0)
  const [loading,setLoading] = useState(false)
  const user = useSelector((state)=> state.user)
  const maxUploadSize = useSelector((state)=> state.dataStructure.jsonMaxSize>maxFrontendUploadSize?maxFrontendUploadSize:state.dataStructure.jsonMaxSize)
  const serviceCode = useSelector((state)=> state.dataStructure.serviceCode)

  const windowHeight = useWindowHeight()
  const originalHeight = useRef(height)
  const zoomButtonRef = useRef()
  
  const handleMouseEnter = () => {
    setMouseOver(true)
  }
  const handleMouseLeave = () => {
    setMouseOver(false)
  }

  const getMeta = (url) =>
  new Promise((resolve, reject) => {
    const img = new Image()
    img.onload = () => resolve(img)
    img.onerror = (err) => reject(err)
    img.src = url
  })

  const fetchPhotosUrls = useCallback(async() => {
    setLoading(true)
    const url = baseUrl + imageContainerModel + '/search';
    const config = {
      headers: {'Authorization': 'Bearer ' + user.token},
      params: {}
    }
    const postBody = {
      "filter":{
        "func": "and",
        "fields": [
          {
            "field": "parent_uuid",
            "value": parentModelUuid,
            "func": "eq"
          }
        ]
      },
      "format": "default",
      "inputformat": "ui",
      "sort": {
        "field": "position"
      }
    }
    try {
      const response = await axios.post(url,postBody,config)
      const recdata = response.data
        if(recdata && recdata.result){
          let photosUrl = []
          for(let k in recdata.result){
            photosUrl.push({'uuid': recdata.result[k].uuid, 'url': mediaUrl + imageModel + '/download/' + recdata.result[k].resource, 'position': recdata.result[k].position})
          }
          if(photosUrl.length || (typeof skipAlternateLoadingMethod !== "undefined" && skipAlternateLoadingMethod)){
            return {"result": photosUrl}
          }
          // Use alternate method in case images are not linked via parent_uuid (e.g. orders cloned from offers)
          // If images are found with this method, make field readonly
          // This method has to be disabled in offer editform, otherwise deleting the last photo load the last deleted photo and disable the field (for a backend bug)
          if(typeof skipAlternateLoadingMethod === "undefined" || !skipAlternateLoadingMethod){
            let photosUrl2 = []
            let result = await loadModels(baseUrl,imageContainerModel,multiValue,user.token)
            if(result.result){
              let rs = result.result
              for(let k in rs){
                photosUrl2.push({'uuid': rs[k].uuid, 'url': mediaUrl + imageModel + '/download/' + rs[k].resource, 'position': rs[k].position})
              }
              if (photosUrl2.length) setMakeReadOnly(true)
              return {"result": photosUrl2}
            }
          }
        }
    } catch(error){
      if(error.response.data.error.code){
        return {"error": get_error_message_by_code(error.response.data.error.code)}
      } else {
        return {"error": 'Errore di rete caricando la galleria!'}
      }
    }
  },[baseUrl, imageContainerModel, user.token, parentModelUuid, skipAlternateLoadingMethod, mediaUrl, imageModel, multiValue])

  useEffect(()=>{
    const fetchPhotos = async () => {
      try {
        // Fetch photos by parent uuid from media
        let urlResult = await fetchPhotosUrls()
        let photoData = [],
            photoUuids = [],
            containerWidth = 0
        if(urlResult.result && Array.isArray(urlResult.result)){
          let photosUrl = urlResult.result
          for(let k in photosUrl){
            const img = await getMeta(photosUrl[k].url)
            let imgheight = height-2
            let width = imgheight * img.naturalWidth / img.naturalHeight
            photoData.push({'uuid': photosUrl[k].uuid, 'src': photosUrl[k].url, 'width': width, 'height': imgheight, 'position': photosUrl[k].position})
            photoUuids.push(photosUrl[k].uuid)
            containerWidth += (width + 4)
          }
          setInnerOffset(0)
          setContainerWidth(containerWidth)
          setPhotos(photoData)
          setNumberOfPhotos(photoData.length)
          let returnValue = arrayToString(photoUuids)
          if (field){
            // Normal edit/new form
            changeHandler(returnValue,field)
          } else {
            // Offerwizard
            changeHandler(null,returnValue,{type:"uuid[]",name:"images"})
          }
          
        }
      } catch(error){
        return {"error": 'Errore caricando le foto: ' + error}
      }
      setLoading(false)
    }
    fetchPhotos()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[height,fetchPhotosUrls,triggerReloadPhotos,field])

  const innerContainer = useRef()
  const outerContainer = useRef()

  const handleScrollRight = (e) => {
    e.preventDefault()
    let isc = innerContainer.current.scrollWidth,
        osc = outerContainer.current.clientWidth
    if(isc > osc){
      let totalOffset = isc - osc,
          step = height
      if((totalOffset-innerOffset) < step){
        step = (totalOffset-innerOffset)
      }
      setInnerOffset(innerOffset+step)
    }
  }

  const handleScrollLeft = (e) => {
    e.preventDefault()
    let isc = innerContainer.current.scrollWidth,
        osc = outerContainer.current.clientWidth
    if(isc > osc){
      let step = height
      if((innerOffset) < step){
        step = (innerOffset)
      }
      setInnerOffset(innerOffset-step)
    }
  }

  const handleAddImage = (target) => {
    let acceptedTypes = ['image/bmp', 'image/jpeg', 'image/x-png', 'image/png', 'image/gif']
    if(target.files && target.files[0]){
      setLoading(true)
      if(acceptedTypes.includes(target.files[0].type)){
        let chunkArray = [],
        fr = new FileReader()
        fr.onload=function(readresult) {
            let maxsize= Math.floor((maxUploadSize - "{\"chunk\":\"\"}".length)/1.34)
            try{
              
              /* let MBs = target.files[0].size/1048576 /* 1MB = 1048576 bytes */
              let MBs = target.files[0].size/1000000 /* 1MB = 1048576 bytes */
              //console.log('file size: '+target.files[0].size+' bytes')
              //console.log('file size: '+(MBs)+' MB')
              if(MBs > 2){
                throw new Error('Exceeded file dimension (max 2MB)'); /* This is max image dimension for Prestashop pubblication of product */
              }
              // read chunks
              for(let i=0;i<target.files[0].size && maxsize;i+=maxsize){
                let chunk=atob(btoa(readresult.target.result.substring(readresult.target.result.indexOf(',') + 1)).substring(i,i+maxsize))
                chunkArray.push(chunk)
                maxsize = chunk.length
              }
            } catch (error) {
              toast.warning('Attenzione: non è possibile caricare immagini più pesanti di 2MB')
              return
            }
            let fileInfo = {
                field_model: imageModel,
                newFileName: target.files[0].name,
                mimetype: target.files[0].type,
            }
            let chunkData = {
              chunks: chunkArray
            }
            // 1 create imagecontainer model
            createResource(baseUrl,imageContainerModel,user.token).then((result)=>{
              if(result.error){
                toast.error(result.error)
              } else if (result.uuid) {
                let imageContainerModelUuid = result.uuid
                // 2 create resource model
                createResource(mediaUrl,imageModel,user.token).then((result)=>{
                  if(result.error){
                    toast.error(result.error)
                  } else if (result.uuid) {
                    fileInfo.uuid = result.uuid
                    // 3 upload file chunks to resource
                    uploadFileChunks(mediaUrl,fileInfo,chunkData.chunks,user.token).then((result)=>{
                      if(result.error){
                        toast.error(result.error)
                      } else {
                        // 4 save resource file
                        saveResource(mediaUrl,fileInfo,imageContainerModelUuid,imageContainerModel,serviceCode,user.token).then((result)=>{
                          if(result.error){
                            toast.error(result.error)
                          } else {
                            // 5 save imagecontainer model
                            let objectInfo = {
                              uuid: imageContainerModelUuid,
                              position: numberOfPhotos,
                              parent_model: parentModel,
                              parent_uuid: parentModelUuid,
                              committed: 1,
                            }
                            objectInfo[imageModel] = fileInfo.uuid
                            saveModel(baseUrl,imageContainerModel,objectInfo,user.token).then((result)=>{
                              if(result.error){
                                toast.error(result.error)
                              } else {
                                let reload = triggerReloadPhotos + 1
                                setTriggerReloadPhotos(reload)
                              }
                            })
                          }
                        })
                      }
                    })
                  }
                })
              }
            })
        }
        fr.readAsDataURL(target.files[0])
          setLoading(false)
      } else {
        toast.warning('Il file selezionato non è un\'immagine valida')
        setLoading(false)
      }
    }
  }

  const deletePhotoByUuid = async (uuid) => {
    let objectData = {
      uuid: uuid,
      deleted: 1
    }
    let result = await deleteModel(baseUrl,imageContainerModel,objectData,user.token)
    if (result.status && result.status === 'ok') {
      return true
    } else {
      toast.error(result.error)
      return false
    }
  }

  const updatePhotoPosition = async (uuid,newPosition) => {
    let objectData = {
      uuid: uuid,
      position: newPosition
    }
    let result = await saveModel(baseUrl,imageContainerModel,objectData,user.token)
    if (result.status && result.status === 'ok') {
      return true
    } else {
      toast.error(result.error)
      return false
    }
  }

  const handleSort = async (uuid) => {
    try {
      let newPhotos = photos,
          expectedPosition = 1
      for (let k in newPhotos) {

        if (newPhotos[k].uuid === uuid) {

          let updatedMainPosition = await updatePhotoPosition(newPhotos[k].uuid,0)
          if (!updatedMainPosition) {
            toast.error('Errore nell\'impostazione immagine di copertina')
          }

        } else {
          if (newPhotos[k].position !== expectedPosition) {
            let updatedPosition = await updatePhotoPosition(newPhotos[k].uuid,expectedPosition)
            if (!updatedPosition) {
              toast.error('Errore nell\'aggiornamento delle posizioni immagini')
            }
          }
          expectedPosition++
        }
      }
      let reload = triggerReloadPhotos + 1
      setTriggerReloadPhotos(reload)
      
    } catch (error) {
      toast.error('Errore durante la cancellazione dell\'immagine')
    }
  }

  const handleDelete = async (uuid) => {
    try {
      let deletedPhoto = await deletePhotoByUuid(uuid)
      if (deletedPhoto) {
        let newPhotos = photos,
            expectedPosition = 0
        for (let k in newPhotos) {
          if (newPhotos[k].uuid !== uuid) {
            if (newPhotos[k].position !== expectedPosition) {
              let updatedPosition = await updatePhotoPosition(newPhotos[k].uuid,expectedPosition)
              if (!updatedPosition) {
                toast.error('Errore nell\'aggiornamento delle posizioni immagini')
              }
            }
            expectedPosition++
          }
        }
        let reload = triggerReloadPhotos + 1
        setTriggerReloadPhotos(reload)
      } else {
        toast.error('Errore durante la cancellazione dell\'immagine')
      }
    } catch (error) {
      toast.error('Errore durante la cancellazione dell\'immagine')
    }
  }

  const handleZoomin = () => {
    let maxHeight1 = windowHeight - 400,
        maxHeight2 = window.innerWidth - 10,
        finalHeight = maxHeight1 < maxHeight2 ? maxHeight1 : maxHeight2
    setHeightFunction(finalHeight)
    zoomButtonRef.current.scrollIntoView({ behavior: 'smooth' })
  }

  const handleZoomout = () => {
    setHeightFunction(originalHeight.current)
  }

  return (
    <>
      <label className='form-label mb-2'>Immagini:</label>
      <div className='border rounded border-even photogallery-container' ref={outerContainer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <button className={'btn mcr-btn btn-micro-with-icon' + (mouseOver && photos.length ? ' photo-button-left':' d-none')} title="Sinistra" onClick={(e) => {handleScrollLeft(e)}} ><span className="material-symbols-rounded">chevron_left</span></button>
      {height > 120 && <button type="button" className='btn mcr-btn btn-micro-with-icon photo-button-zoomout' title="Torna alle miniature" onClick={() => {handleZoomout()}} ><span className="material-symbols-rounded">zoom_out</span></button>}
      <button className={'btn mcr-btn btn-micro-with-icon'+ (mouseOver && photos.length ? ' photo-button-right':' d-none')} title="Destra" onClick={(e) => {handleScrollRight(e)}} ><span className="material-symbols-rounded">chevron_right</span></button>
        <div className='photos-inner-container' ref={innerContainer} style={{width: containerWidth+'px', height: height+'px', padding: '1px', marginLeft: '-'+innerOffset+'px'}}>
          {loading && <div className='loading-overlay d-flex justify-content-center align-items-center'><Spinner height={height/3} width={height/3}/></div>}
          {makeDisable && <div className='loading-overlay half-opacity d-flex justify-content-center align-items-center'></div>}
          {!loading && photos.map((pic,idx)=>{
            return <SinglePhoto
              mouseOver={mouseOver}
              uuid={pic.uuid}
              position={pic.position}
              key={idx}
              url={pic.src}
              width={pic.width}
              height={pic.height}
              className={idx!==(photos.length-1) ? 'border border-even me-1':'border border-even'}
              handleDelete={handleDelete}
              handleSort={handleSort}
              makeDisable={makeDisable || makeReadOnly}
            />
          })}
          {!photos.length && <h6 className='h6 no-photos-info text-even'>{parentModel==='offer'?'Carica l\'immagine del tuo vino':'Nessuna immagine caricata'}</h6>}
        </div>
      </div>
      {!makeDisable && !makeReadOnly && <div className='mt-1 float-end'>
        <div style={{position: 'relative', width: '34px'}}>
          <input type='file' className='form-control' name='carica' style={{zIndex: 2}} onChange={(e) => {handleAddImage(e.target)}}/>
          <button type="button" className='btn mcr-btn btn-micro-with-icon cream ms-0' title="Aggiungi" onClick={() => {return false}} ><span className="material-symbols-rounded">add</span></button>          
        </div>
      </div>}
      {height <= 120 && setHeightFunction && <button type="button" ref={zoomButtonRef} className='btn mcr-btn btn-micro-with-icon no-icon-sm ms-0 mt-1' style={{padding: '0px 6px 6px 6px'}} title="Ingrandisci" onClick={() => {handleZoomin()}} ><span className="material-symbols-rounded">zoom_in</span></button>}
    </>
  )
}

export default Photogallery
